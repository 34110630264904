import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OffCanvasMenu from './OffCanvasMenu';

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };



  let scrollToSection = (el) => {
    const element = document.getElementById(el.target.getAttribute('scrollTo'));
    element?.scrollIntoView({
      behavior: 'smooth'
    });
  };
  return (
    <>
      <header id="0"
              className={`main-header ${
          navDark ? 'position-absolute ' : ''
        } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? 'navbar-dark' : 'navbar-light'
          } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className='container d-flex align-items-center justify-content-lg-between position-relative'>
            <Link
              to='/'
              className='navbar-brand d-flex align-items-center mb-md-0 text-decoration-none'
            >
              <div className='navbar-logo'>
                {!navDark || scroll > headerTop ? (
                  <img
                    src='assets/logo/logo_color.png'
                    alt='logo'
                    className='img-fluid'
                  />
                ) : (
                  <img
                    src='assets/logo/logo_white.png'
                    alt='logo'
                    className='img-fluid'
                  />
                )}
              </div>
            </Link>
            <Link
              className='navbar-toggler position-absolute right-0 border-0'
              to='#offcanvasWithBackdrop'
              role='button'
            >
              <span
                className='far fa-bars'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasWithBackdrop'
                aria-controls='offcanvasWithBackdrop'
              ></span>
            </Link>

            <div className='collapse navbar-collapse justify-content-center'>
              <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
                <li>
                  <Link scrollTo="0" to='/' scrollTo="0" className='nav-link' onClick={scrollToSection}>
                    Leistungen
                  </Link>
                </li>
                <li>
                  <a
                      style={{cursor: "pointer"}}
                      className='nav-link' scrollTo="2" onClick={scrollToSection}>
                    Migration
                  </a>
                </li>
                <li>
                  <a
                      style={{cursor: "pointer"}}
                      className='nav-link' scrollTo="3" onClick={scrollToSection}>
                    Andere Leistungen
                  </a>
                </li>
                <li>
                  <a
                      style={{cursor: "pointer"}}
                      className='nav-link' scrollTo="4" onClick={scrollToSection}>
                    Kontakt
                  </a>
                </li>
              </ul>
            </div>

            <div className='action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block'>
              <a
                  href='mailto:info@elintech.de'
                  className='btn btn-primary mt-auto'
              >
                Beratung
              </a>
            </div>
            <OffCanvasMenu/>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;

