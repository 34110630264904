import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import NotFoundScreen from '../components/others/NotFoundScreen';

import ScrollToTop from '../components/common/ScrollToTop';


import HomeNine from '../themes/index9/HomeNine';
import Impressum from '../pages/Impressum';
import Agb from '../pages/Agb';
import DataProtection from '../pages/DataProtection';

function index() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomeNine />} />


        <Route exact path='/impressum' element={<Impressum />} />
        <Route exact path='/agb' element={<Agb />} />
        <Route exact path='/data-protection' element={<DataProtection />} />

        <Route element={<NotFoundScreen />} />
      </Routes>
    </HashRouter>
  );
}

export default index;

