import React from 'react';
import AboutImage from '../../components/about/AboutImage';
import PageMeta from '../../components/common/PageMeta';
import CtaTwo from '../../components/cta/CtaTwo';

import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroNine from './HeroNine';

import FaqTwo from '../../components/faqs/FaqTwo';
import FeatureImgSix from '../../components/features/FeatureImgSix';

import FeatureOne from '../../components/features/FeatureOne';
import WorkProcessFour from "../../components/work-process/WorkProcessFour";
import FeatureTwo from "../../components/features/FeatureTwo";
import ContactBox from "../../components/contact/ContactBox";

const HomeNine = () => {
  return (
    <Layout>
      <PageMeta title='elintech GmbH' />
      <Navbar />
      <HeroNine />
      {/*<AboutImage />*/}
      {/*<FeatureOne />*/}
      <FeatureImgSix />
      <FeatureTwo />
      {/*<WorkProcessFour />*/}
        <ContactBox />
     {/* <FaqTwo />*/}
      {/*<CtaTwo />*/}

      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeNine;

