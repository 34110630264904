import React from 'react';
import { Link } from 'react-router-dom';

const OffCanvasMenu = () => {

  let scrollToSection = (el) => {
    document.getElementsByClassName("close-btn")[0].click();
    const element = document.getElementById(el.target.getAttribute('scrollTo'));
    element?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasWithBackdrop'
      >
        <div className='offcanvas-header d-flex align-items-center mt-4'>
          <Link
            to='/'
            className='d-flex align-items-center mb-md-0 text-decoration-none'
          >
            <div className='navbar-logo'>
              {' '}
              <img
                src='assets/logo/logo_color.png'
                alt='logo'
                className='img-fluid'
              />
            </div>
          </Link>
          <button
            type='button'
            className='close-btn'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          >
            <i className='far fa-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>

          <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
            <li>
              <Link scrollTo="0" to='/' scrollTo="0" className='nav-link' onClick={scrollToSection}>
                Leistungen
              </Link>
            </li>
            <li>
              <a
                  style={{cursor: "pointer"}}
                  className='nav-link' scrollTo="2" onClick={scrollToSection}>
                Migration
              </a>
            </li>
            <li>
              <a
                  style={{cursor: "pointer"}}
                  className='nav-link' scrollTo="3" onClick={scrollToSection}>
                Andere Leistungen
              </a>
            </li>
            <li>
              <a
                  style={{cursor: "pointer"}}
                  className='nav-link' scrollTo="4" onClick={scrollToSection}>
                Kontakt
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
