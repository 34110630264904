import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgSix = () => {
  return (
    <>
      <section id="2" className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <SectionTitle
                    title='Warum sind Legacy-Systeme langsam und teuer zu warten?'
                    description='Legacy-Systeme sind langsam und teuer zu warten, weil sie auf veralteter Technologie basieren, die oft nicht mit modernen Standards kompatibel ist. Die Mitarbeiter, die diese Systeme ursprünglich entwickelt haben, sind häufig nicht mehr im Unternehmen, und die Architektur ist meist unzureichend dokumentiert. Dadurch wird oft weiterentwickelt, ohne ein vollständiges Verständnis der bestehenden Architektur zu haben, was die Systeme noch komplexer und schwerer wartbar macht. Zusätzlich sind sie schwer skalierbar, reagieren schlecht auf neue Anforderungen und bergen Sicherheits- sowie Integrationsprobleme, die die Kosten weiter in die Höhe treiben.'
                    leftAlign
                />

                <b style={{color: "black"}}>Der einzige Weg, die Situation zu verbessern, ist, das System schrittweise
                  zu migrieren, indem man
                  kleine Teile auf eine neue Infrastruktur überträgt. Wir bieten Migration folgender Systeme an: </b>
                <br/><br/>
                <ul className='list-unstyled mb-0'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-code text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Java EE → Spring</h3>
                      <p>
                        Migration von Java EE zu Spring Boot um die Wartbarkeit und Performance zu verbessern.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-success rounded me-4'>
                      <i className='fas fa-code text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Spring → Spring Boot</h3>
                      <p>
                        Einführung von Spring Boot, um Konfigurationsaufwand zu reduzieren und neue Features schneller
                        bereitzustellen.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-secondary rounded me-4'>
                      <i className='fas fa-code text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Spring MVC → Spring WebFlux</h3>
                      <p>
                        Wechsel zu einem reaktiven Konzept, um Performance-Probleme zu lösen.
                        Es lohnt sich wenn ihre
                        Anwendung hunderte Anfragen oder mehr in Sekundentakt verarbeiten muss.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-star mb-4'>
                    <div className='icon-box bg-warning rounded me-4'>
                      <i className='fas fa-desktop text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Manueller Release-Prozess → CI/CD mit Jenkins</h3>
                      <p>
                        Automatisierung der Bereitstellung von Anwendungen mit Jenkins und Kubernetes, um die Anwendung einfacher auf die Live-Umgebung auszurollen.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-info rounded me-4'>
                      <i className='fas fa-network-wired text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Monolith → Microservices in der Cloud</h3>
                      <p>
                        Umstellung auf skalierbare, cloud-native Architekturen in Microsoft Azure Umgebung für leichtere
                        Skalierung und Wartung.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fas fa-server text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>SQL → NoSQL/Time-Series</h3>
                      <p>
                        Einsatz spezialisierter Datenbanken für mehr Performance und effiziente Verarbeitung großer
                        Datenmengen.
                      </p>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                    src='assets/img/feature-hero-img.svg'
                    alt='feature'
                    className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgSix;

